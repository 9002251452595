.rboard-container {
  max-width: 1280px;
  margin: 0 auto;
  .rboard-wrap {
    width: 95%;
    margin: 0 auto;

    .rboard {
      width: 100%;
      margin: 0 auto;
      line-height: 2.5rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid #c1c1c1;
      text-align: center;
      thead {
        color: black;
        height: 10px;
        // background-color: #e9e9e9;
        border-bottom: 1px solid black;
        tr {
          td {
            text-align: center;
            strong {
              font-size: 18px;
              @media (max-width: 320px) {
                font-size: 15px;
              }
            }
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #c1c1c1;
          color: black;
        }
        td {
          text-align: center;
          &:first-child {
            width: 8%;
            text-align: center;
            @media (max-width: 768px) {
              width: 10%;
            }
            @media (max-width: 561px) {
              width: 15%;
            }
          }
          &:nth-child(2) {
            width: 10%;
            @media (max-width: 850px) {
              width: 15%;
            }
          }
          &:nth-child(3) {
            text-align: left;
          }
          &:nth-child(4) {
            width: 10%;
            @media (max-width: 850px) {
              width: 15%;
            }
            @media (max-width: 425px) {
              width: 18%;
            }
            @media (max-width: 347px) {
              width: 20%;
            }
          }
          &:nth-child(5) {
            width: 10%;
          }
          &:last-child {
            width: 10%;
            @media (max-width: 850px) {
              width: 15%;
            }
            @media (max-width: 561px) {
              width: 25%;
            }
            @media (max-width: 347px) {
              width: 23%;
            }
          }
        }
      }
    }
    .bottompagination {
      display: flex;
      justify-content: center;
    }
  }

  .rboardwrite {
    text-align: right;
    margin-bottom: 0.8rem;
  }

  .searchBox {
    vertical-align: bottom;
    display: flex;
    select {
      margin-right: 1rem;
      height: 37px;
      padding-right: 15px;
    }
    input {
      margin-right: 10px;
      border: 1px solid #c1c1c1;
      height: 37px;
      border-radius: 0;
    }
    button {
      border: 0;
      background-color: var(--color-primary);
      width: 150px;
      height: 37px;
      border-radius: 2px;
      color: white;
      text-align: center;
      vertical-align: bottom;
      cursor: pointer;
    }
  }
  .rboardwrite-btn {
    border: 0;
    background-color: var(--color-primary);
    padding: 0.37rem 1rem;
    border-radius: 2px;
    color: white;
    text-align: center;
    vertical-align: bottom;
    cursor: pointer;
  }
}
.rboardBottom {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 346px) {
  .rboardBottom input {
    width: 100px;
  }
}
@media (max-width: 257px) {
  .rboardBottom input {
    width: 50px;
  }
}

//   .rboard
//   .rboard tbody tr{
//     border-bottom: 1px solid #c1c1c1;
//     color: black;
//   }

//   .rboard tbody td:first-child{
//     width: 10%;
//   }
//   .rboard tbody td:nth-child(2){
//     width: 10%;
//     font-size: 11px;
//   }
//   .rboard tbody td:nth-child(3){
//    text-align: left;
//   }
//   .rboard tbody td:nth-child(4){
//     width: 10%;
//   }
//   .rboard tbody td:nth-child(5){
//     width:5%;
//   }
//   .rboard tbody td:last-child{
//     width: 10%;
//   }

.pagination .page-link {
  color: black;
  &:active {
    border-color: grey;
    color: white;
    a {
      &:hover {
        border-color: grey;
        color: black;
      }
    }
  }
}

//   @media(max-width : 768px){
//     .rboard tbody td:first-child{
//       width: 10%;
//     }
//     .rboard tbody td:nth-child(2){
//       width: 15%;
//       /* font-size: 10px; */
//       text-align: center;
//     }

//     .rboard tbody td:nth-child(4){
//       width: 15%;
//     }
//     .rboard tbody td:last-child{
//       width: 20%;
//     }
//   }
