.registerconfirm-container {
  padding: 200px 0 100px 0;
  width: 80%;
  margin: 0 auto;
  text-align: left;
  @media (max-width: 500px) {
    width: 85%;
    margin: 0 auto;
  }
  h2 {
    font-size: 2rem;
  }
  p {
    color: var(--color-dark);
    font-size: 1rem;
  }
  .contract {
    padding: 1rem;

    @media (max-width: 500px) {
      padding: 0 !important;
    }
  }
  br{
    display: block;
  }
  .privacyStatement {
    height: 19rem;
    border: 1px solid #c1c1c1;
    background-color: white;
    white-space: pre-line;
    overflow-y: scroll;
    text-align: left;
    padding: 1.8rem;
    width: 100%;
    line-height: 1.8rem;
  }

  .contract-title {
    font-size: 1.2rem;
    padding: 1rem 0;
  }

  .agreebox {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 1rem 0;
    input{
        width: 20px;
        margin-right: 10px;
    }
    @media (max-width: 500px) {
      justify-content: start;
    }
  }
  .registerconfirm-Btn {
    text-align: center;
    margin-top: 4rem;

    // button {
    //   padding: 0.5rem 2.8rem;
    //   font-size: 1.5rem;
    //   border: none;
    //   background: var(--color-dark);
    //   color: white;
    //   border-radius: 0.2rem;
    // }
  }
}
