.Mentoring-details-wrap {
  .Mentoring-details {
    input[type="checkbox"] {
      width: 20px;
    }
    .application-table {
      color: black;
      font-size: 17px;
      border-bottom: none;
      td {
        &:first-child {
          width: 12%;
        }
        label{
         margin: 0 10px;
       }
      }
      thead {
        td {
          &:nth-child(2) {
            width: 22.5%;
          }
          
        }
      }

      input[type=text] {
        width: 70%;
        border-radius: 0;
        margin-left: 5px;
      }
      select {
        padding-left: 5px;
      }
      .time-btn {
        padding: 8px 5px;
        border-radius: 5px;
        margin: 5px 3px;
 
        &:hover {
          background-color: var(--color-primary);
          color: white;
          border: 1px solid var(--color-primary);
        }
        &.selected {
          background-color: var(--color-primary);
          color: white;
          border: 1px solid var(--color-primary);
        }
      }
      //  .input-text-align{
      //    display: flex;
      //    align-items: center;
      //  }
     
    }
    @media (max-width:500px) {
      font-size: 15px;
      margin: 0 15px;
      .application-table {
        td{
          &:first-child{
            width: 25%;
          }
        }
      }
     }
  }
}
