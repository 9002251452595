.login-container{
      padding: 200px 0 100px 0;
      
    .login-box {   
        padding: 20px 50px;
        border-radius: 5px;
        max-width: 430px;
        margin: 0 auto;  
        font-size:1rem;
        h2 {
            margin-bottom:1.6rem;
            font-size: 2rem;
            font-weight: bold;
            text-align: center;
          }
        label {
            font-weight: bold;
            font-size: 14px;
          }
          .errorMessage{
            color: red;
            font-size: 0.9rem;
            margin-top: 0.3rem;
            text-align: left;
          }
          .loginBtn {
            border: 0;
            background-color: black;
            color: white;
            padding: 0.5rem 0.6rem;
            font-size: 0.8rem;
            border: 1px solid #ccc;
            border-radius: 3px;
            cursor: pointer;
            margin-top: 1rem;
            font-size: 1.1rem;
            &:disabled{
                background-color: #dadada;
                color: white;
                font-size: 1.1rem;
            }
          }
    
          input {
            outline: 0;
            border: 1px solid #c1c1c1;
            margin-top: 7px;
            border-radius: 4px;
            width: 100%;
            padding: 12px 8px;
            font-size: 1.1rem;
          }
          .id-container {
            width: 100%;
           a{
            label{
                font-size: 1rem;
                color: rgb(87, 87, 87);
                padding: 1rem;

            }
           }
          }
      }
     
}
