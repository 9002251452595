.modal-content{
    width: 90% !important;
    // height:95vh ;
    text-align: center;
    // @media(min-width:837px) {
    //     width: 90%;
    //     height: 95vh;
    //    img{
    //     height: 70vh;
    //     width: auto;
    //    }
    // }
    @media(min-width: 1200px){
        width: 90%;
        height: 95vh;
        img{
            height: 70vh;
            width: auto;
        }
    }
}