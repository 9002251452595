.mypage-container {
  max-width: 1280px;
  padding-top: 200px;
  margin: auto;
  @media (max-width: 437px) {
    padding-top: 120px;
  }
  @media (max-width: 365px) {
    padding-top: 120px;
  }
  .mypage-modal {
    &.modal-content {
      background-color: red !important;
      @media (min-width: 1200px) {
        img {
          width: auto;
        }
      }
    }
  }
  .contentMypageboard {
    @media (max-width: 900px) {
      font-size: 23px;
    }
  }
  h4 {
    @media (max-width: 500px) {
      font-size: 20px;
      margin-top: 30px;
    }
  }
  input {
    border-radius: 0px;
  }

  table {
    border: none;
    tr {
      td {
        color: black;
        &:first-child {
          font-size: 18px;
          @media (max-width: 500px) {
            font-size: 15px;
          }
        }
        &:nth-child(2) {
          font-size: 16px;
          @media (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .nav-item {
    text-align: center;
    @media (max-width: 437px) {
      width: 33%;
      height: 60px;
      line-height: 54px;
    }
    @media (max-width: 365px) {
      width: 33%;
      height: 60px;
      line-height: 54px;
    }
    @media (max-width: 338px) {
      width: 120px;
      height: 55px;
      line-height: 35px;
    }
    @media (max-width: 316px) {
      width: 110px;
      height: 55px;
    }
    @media (max-width: 300px) {
      width: 100px;
      height: 55px;
    }
  }
  .nav-pills {
    @media (max-width: 437px) {
      flex-direction: row !important;
    }
    @media (max-width: 365px) {
      flex-direction: row !important;
    }
    & .nav-link {
      color: var(--color-body) !important; /* 원하는 텍스트 색상 */
      @media (max-width: 437px) {
        font-size: 13px;
        height: 70px;
      }
      @media (max-width: 365px) {
        font-size: 13px;
      }
      &.active {
        background-color: var(--color-primary) !important;
        color: white !important;
      }
    }
  }
  .reservaton-tab {
    cursor: pointer;
    color: black;
    // border: 1px solid var(--color-tablegrey);
    width: 145px;
    height: 45px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1200px) and (min-width: 990px) {
      width: 150px;
      height: 55px;
    }
    @media (max-width: 437px) {
      width: 33%;
      height: 55px;
      font-size: 15px;
    }
    @media (max-width: 365px) {
      width: 33%;
      height: 55px;
    }
    @media (max-width: 338px) {
      width: 120px;
      height: 55px;
    }
    @media (max-width: 316px) {
      width: 110px;
      height: 55px;
    }
    @media (max-width: 300px) {
      width: 100px;
      height: 55px;
    }
    &.active-tab {
      // background-color: var(--color-primary);
      font-weight: bold;
      border-bottom: 2px solid var(--color-primary);
    }
  }
  .reservation-content {
    display: none;
    &.active-content {
      display: block;
    }
  }
}

.reservation-table {
  margin-top: 30px;
  tr {
    border-bottom: 1px solid var(--color-tablegrey);
  }
  th {
    text-align: center;
    color: black;
    font-size: 17px;
    // line-height: 60px;
    @media (max-width: 500px) {
      font-size: 13px;
      // line-height: 50px;
    }
  }
  td {
    text-align: center;
    @media (max-width: 500px) {
      font-size: 14px;
    }
    &:first-child {
      width: 10%;
    }
    &:nth-child(2) {
      width: 25%;
    }

    //    border: 1px solid var(--color-tablegrey);
  }

  .cancle-btn {
    padding: 4px 10px;
    color: red;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
  }
  .extend-btn {
    padding: 4px 10px;
    color: blue;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
  }
  .work-register-btn {
    padding: 4px 10px;
    color: blue;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
  }
}

.mypage-board-table {
  tr {
    border: 1px solid var(--color-tablegrey);
  }
  th {
    text-align: center;
    color: black;
    font-size: 17px;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  td {
    text-align: center;
    &:first-child {
      width: 10%;
      @media (max-width: 715px) {
        width: 15%;
      }
    }
    &:nth-child(2) {
      text-align: left;
    }
    &:last-child {
      width: 20%;
      @media (max-width: 715px) {
        width: 30%;
      }
    }
    @media (max-width: 500px) {
      font-size: 13px;
    }
    //    border: 1px solid var(--color-tablegrey);
  }
}
