.main-container{
  .main-inner{
    max-width: 1280px;
  }
}
.rn-blog-area{
  max-width: 1280px;
  margin: 0 auto;
  .container{
     width: 90%;
     margin: 0 auto;
  };
  // @media (max-width: 500px) {
  //   p {
  //     white-space: nowrap;
  //   }
  // }
}
.open-modal{
  .modal-content{width: auto !important;
    @media (max-width:600px) {
      width: 90% !important;
    }
  }
  
}
