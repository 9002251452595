.vspace-floor{
    max-width: 1280px;
    margin: 100px  auto;
    padding: 100px 0;
    @media(max-width:500px){
      padding: 0;
    }   
     .container{
    width: 90%;
    margin:0 auto;
    position: relative;
    padding: 100px 0;
  
    .floorimg{
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media(max-width: 760px){
        width: 100%;
    }
  }
    .img-first{
        width: 330px;
        position: absolute;
        top: 30%;
        left: 24%;
        z-index: 1;
        cursor: pointer;
        transition: all 1s;
     &:hover{
        transform: scale(1.1);
     }
   @media(max-width: 1280px){
         width: 257px;
        top: 36%;
     }
     @media(max-width: 1000px){
        width: 200px;
        top:42%;
     }
    //  @media(max-width: 900px){
    //     width: 200px;
    //     top: 14%;

    //  }
     @media(max-width: 760px){
        width: 250px;
        left: 8%;
        top: 37%;
     }
     @media(max-width: 562px){
        width: 250px;
        left: 5%;
        top: 37%;
     }
     @media(max-width: 460px){
        width: 50%;
        left: 5%;
        top: 43%;
     }
       @media(max-width: 438px){
        // width: 50%;
        left: 5%;
        top: 45%;
     }

     @media(max-width: 400px){
        top: 43%;
     }
     @media(max-width: 340px){
        // width: 50%;
        top: 43%;
     }
     @media(max-width: 280px){
        top: 42%;
     }
    }
    .img-second{
        width: 320px;
        position: absolute;
        top: 0%;
        left: 24%;
        z-index: 3;
        cursor: pointer;
        transition: all 1s;
        &:hover{
           transform: scale(1.1);
        }
        @media(max-width: 1280px){
            width: 257px;
            top: 10%;
         }
         @media(max-width: 1000px){
            width: 200px;
            top: 22%;
         }

        //  @media(max-width: 900px){
        //     width: 200px;
        //     top: -8%;
    
        //  }
         @media(max-width: 760px){
            width: 250px;
            left: 8%;
            top: 10%;
         }    
        
         @media(max-width: 562px){
            width: 250px;
            left: 5%;
            top: 10%;
         }
         @media(max-width: 460px){
            width: 50%;
            top: 24%;
         }
         @media(max-width: 438px){
            // width: 50%;
            left: 5%;
            top: 30%;
         }
         @media(max-width: 400px){
            top: 29%;
         }
         @media(max-width: 340px){
            // width: 50%;
            top: 30%;
         }
         @media(max-width: 280px){
            top: 32%;
         }
         
        }
    .img-third{
        width: 320px;
        position: absolute;
        top: -30%;
        left: 24%;
        z-index: 5;
        cursor: pointer;
        transition: all 1s;
       &:hover{
        transform: scale(1.1);
       }
        @media(max-width: 1280px){
             width: 257px;
            top: -20%;
         }
         @media(max-width: 1000px){
            width: 200px;
            top: 1%;
    
         }
        //  @media(max-width: 900px){
        //     width: 200px;
        //     top: -29%;
    
        //  }
         @media(max-width: 760px){
            width: 250px;
            left: 8%;
            top: -19%;
         }
         @media(max-width: 562px){
            width: 250px;
            left: 5%;
            top: -15%;
         }
         @media(max-width: 460px){
            width: 50%;
            top: 2%;
         }
         @media(max-width: 438px){
            left: 5%;
            top: 10%;
         }

         @media(max-width: 400px){
            top: 13%;
         }
         @media(max-width: 340px){
            top: 15%;
         }
         @media(max-width: 280px){
            top: 20%;
         }
    }
   }
}
