.boardDetail-wrap {
  max-width: 1280px;
  margin: 0 auto;
  text-align: left;
  line-height: 2.4rem;
  color: var(--color-dark);
  a {
    text-decoration: underline;
  }
  .boardDetail {
    width: 95%;
    margin: 0 auto;
    thead {
      // color: var(--color-dark);
      color: #fff;
      height: 80px;
      // background-color: var(--color-tablegrey);
      background-color: var(--color-dark);
      text-align: center;
      border-top: 1px solid var(--color-dark);
      border-bottom: 1px solid var(--color-lightgrey);
      padding: 10px 20px;

      td {
        text-align: center;
      }
    }
    strong {
      font-size: 18px;
    }
    tbody {
      tr {
        border-bottom: 1px solid var(--color-lightgrey);
        &:nth-child(3) {
          min-height: 150px;
        }
      }
      td {
        font-size: 15px;
        padding: 5px 10px;
      }
      .downfile {
        color: var(--color-board);
        // text-decoration: none;
      }
    }
  }
}

.area-write {
  margin-top: 15px;
  padding: 5px 10px;
}
.ql-editor {
  min-height: 150px;
}
.ql-editor img {
  max-width: 100%;
  height: auto;
}

.area-write .box-textarea input {
  width: 100%;
  height: 40px;
  padding: 2px 10px;
  border: 1px solid #c1c1c1;
  font-size: 13px;
  /* color: #999; */
}

.area-write .box-write {
  display: flex;
  justify-content: space-between;
}

.area-write .xe-label .secret {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #bebfc5;
  border-radius: 2px;
}
.area-write .xe-label .xe-label-text {
  margin-left: 5px;
}

.area-write .box-textarea .btn_register {
  width: 100px;
  height: 40px;
  background: rgb(45, 52, 62);
  border: 1px solid rgb(46, 52, 62);
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.reply-list {
  padding: 0 15px;
  font-size: 13.5px;
  font-weight: 600;
  border-bottom: 1px solid #e8e8e8;
}
.reply-container {
  padding: 0 15px;
  border-bottom: 1px solid #e8e8e8;
}
.reply-content {
  margin-top: -10px;
}

.reply-name {
  font-weight: bold;
  margin-right: 10px;
  font-size: 14px;
}

.reply-day {
  color: grey;
  font-size: 12px;
}

.reply-button span {
  margin-right: 6px;
}
.reply-button span:hover {
  cursor: pointer;
}

@media (max-width: 800px) {
  .boardDetail .board-title {
    font-size: 15px;
  }
  /* .boardDetail thead{ 
      height: 80px !important;
    } */
}

@media (max-width: 500px) {
  .boardDetail .board-title {
    font-size: 12px;
  }
  /* .boardDetail thead{ 
      height: 80px !important;
    } */
}
.boardBtn-wrap {
  width: 95%;
  margin: 0 auto;
  text-align: right;
  margin-top: 2rem;
  button {
    border: 0;
    background-color: var(--color-primary);
    height: 2.4rem;
    padding: 0 1rem;
    border-radius: 2px;
    color: white;
    text-align: center;
    cursor: pointer;
    margin-left: 0.6rem;
  }
}
