/*-----------------
    Brand Area 
------------------*/

ul {
    &.brand-list {
        @extend %liststyle;
        max-width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        // border-left: 1px solid rgba(0, 0, 0, 0.05);
        // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        @media #{$sm-layout} {
            border-left: 0 none;
            border-bottom: 0 none;
        }
        li {
            //flex-basis: 10%;
            // border-top: 1px solid rgba(0, 0, 0, 0.05);
            // border-right: 1px solid rgba(0, 0, 0, 0.05);
            @extend %transition;
            cursor: pointer;
            
            @media #{$sm-layout} {
                border-top: 0 none;
                flex-basis: 30%;
                border-right: 0 none;
                height: auto;
            }

            img {
                max-width: 300px;
             
                @extend %transition;
                @media #{$md-layout} {
                    max-width: 200px;
                    height: auto;
                }
                @media #{$sm-layout} {
                    max-width: 150px;
                    height: auto;
                }
                @media(max-width:500px){
                    max-width: 100px;
                    height: auto;
                }
                @media(max-width:360px){
                    max-width: 80px;
                    height: auto;
                }
            }
            // &:hover{
            //     background-color: var(--color-primary);
            //     transform: scale(1.1);
            //     border-radius: 6px;
            //     img{
            //         opacity: 1;
            //     }
            //}
            
        }

        &.branstyle--2 {
            li {
                flex-basis: 33.33%;
            }
        }
    }
}

ul{
    &.brand-style-2 {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        @media #{$large-mobile} {
            margin: 0 -15px;
        }
        li {
            padding: 25px;
            flex-basis: 20%;
            text-align: center;
            @media #{$sm-layout} {
                flex-basis: 33.33%;
                padding: 17px 10px;
            }
            @media #{$large-mobile} {
                flex-basis: 33.33%;
                padding: 0 15px;
                margin-bottom: 16px;
            }
        }
        img {
            opacity: 0.8;
            transition: 0.3s;
            max-height: 110px;
            max-width: 150px;
            filter: grayscale(1);
            cursor: pointer;
            @media #{$lg-layout} {
                max-width: 129px;
            }
            @media #{$md-layout} {
                max-height: 79px;
            }
            @media #{$sm-layout} {
                max-height: 60px;
                max-width: 90px;
            }
            @media #{$large-mobile} {
                max-height: initial;
            }
            &:hover{
                transform: scale(1.1);
                opacity: 1;
                filter: grayscale(0);
            }
        }
        
    }
}

.react-parallax-content{
    .brand-style-2 {
        a {
            img {
                filter: brightness(0) invert(1);
            }
            &:hover {
                img {
                    filter: brightness(1) invert(0);
                }
            }
        }
    }
}

.brand-separation {
    position: relative;
    padding-bottom: 217px;
    margin-bottom: -117px;
    padding-top: 60px;
    background: #fff;
    
    @media #{$sm-layout} {
        margin-bottom: 0;
        padding-bottom: 60px;
    }

    @media #{$md-layout} {
        margin-bottom: 0;
        padding-bottom: 60px;
    }
}







