.findIdTab-wrap{
  padding: 4rem 0;
  width: 30rem;
  margin: 0 auto;
  @media(max-width:550px){
    width: 26rem;
   }
  @media(max-width:457px){
    width: 20rem;
   }
  .findIdTitle{
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
  }
  .tabtitle{
    background-color: white;
    flex:1;
    padding: 8px 10px;
    font-size: 1.3rem;
    color:black;
    border:1px solid grey;
    cursor: pointer;
    text-align: center;
     @media(max-width:550px){
      padding: 10px 0;
      font-size: 1rem;
     }
    &.active-tab{
      background-color: black;
      color:white;
      border: none;
    }
  }
  .findIdContent{
    display: none;
    position: relative;
    &.active-content{
      display:block;
      margin: 1.6rem 3rem;
    
      input{
        outline: 0;
        border: 1px solid #c1c1c1;
        margin-top: 7px;
        border-radius: 4px;
        width: 80%;
        padding: 12px 8px;
        font-size: 1.1rem;
        @media(max-width:550px){
          width: 100%;
         }
      }
      .confirmbtn {
        border: 0;
        background-color: black;
        color: white;
        padding: 12px 8px;
        font-size: 1.1rem;
        border-radius: 3px;
        cursor: pointer;
        margin-top:7px;
        text-align: center;
      }
      
    }
   
}

.login-control{
  text-align: center;
}
.findIdfooter{
  text-align: left;
  line-height: 0.3rem;
  margin-left: 2rem;
  p{
    color:grey;
    margin-left: 1rem;
    margin:0px;
    @media(max-width:550px){
     font-size: 13px !important;
    }
  }
}
}
/*
.findIdTab{
    border: 1px solid grey;
 }*/











