.board-container {
  max-width: 1280px;
  margin: 0 auto;
  .fnqboard-wrap {
    width: 95%;
    margin: 0 auto;
    .accordion {
      .item {
        border: 1;
        border-radius: 0;
        .accordion-header button {
          background-color: transparent;
          box-shadow: none;
          font-weight: 700;
        }
        .accordion-body {
          font-size: 15px;
        }
        .accordion-button {
          color: var(--color-primary);

          &.collapsed {
            color: black;
          }
        }
      }
    }
  }

  .board-wrap {
    width: 95%;
    margin: 0 auto;
    .boardwrite {
      text-align: right;
      margin-bottom: 0.8rem;

      .boardwrite-btn {
        border: 0;
        background-color: var(--color-primary);
        padding: 8px 15px;
        border-radius: 2px;
        color: white;
        text-align: center;
        vertical-align: bottom;
        cursor: pointer;
      }
    }
    .board {
      width: 100%;
      margin: 0 auto;
      line-height: 2.5rem;
      margin-bottom: 1.2rem;
      border-bottom: 1px solid #c1c1c1;
      text-align: center;
      thead {
        color: #000;
        height: 10px;
        // background-color: #000;
        // border-top: 1px solid black;
        border-bottom: 1px solid black;
        tr {
          td {
            text-align: center;
            strong {
              font-size: 18px;
              @media (max-width: 320px) {
                font-size: 15px;
              }
            }
          }
        }
      }
      tbody tr {
        border-bottom: 1px solid var(--color-midgrey);
        color: black;
        td {
          text-align: center;
        }
      }
      td {
        &:first-child {
          width: 10%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:last-child {
          width: 15%;
        }
        @media (max-width: 455px) {
          &:first-child {
            width: 15%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:last-child {
            width: 20%;
          }
        }
      }
    }
    .boardBottom {
      display: flex;
      justify-content: space-between;
    }
    .qboard {
      width: 100%;
      margin: 0 auto;
      line-height: 2.5rem;
      margin-bottom: 1.2rem;
      border-bottom: 1px solid #c1c1c1;
      text-align: center;
      thead {
        color: black;
        height: 10px;
        // background-color: #e9e9e9;
        border-bottom: 1px solid black;
        tr {
          td {
            text-align: center;
            strong {
              font-size: 18px;
            }
          }
        }
      }
      tbody tr {
        border-bottom: 1px solid var(--color-midgrey);
        color: black;
        td {
          text-align: center;
          &:first-child {
            text-align: left;
            padding-left: 20px;
          }
        }
      }
      td {
        &:first-child {
          width: 60%;
        }
        &:nth-child(2) {
          width: 10%;
        }

        &:last-child {
          width: 10%;
        }
        @media (max-width: 700px) {
          &:nth-child(2) {
            width: 15%;
          }

          &:last-child {
            width: 15%;
          }
        }
        @media (max-width: 500px) {
          &:first-child {
            width: 50%;
          }
          &:nth-child(2) {
            width: 15%;
          }

          &:last-child {
            width: 15%;
          }
        }
        @media (max-width: 433px) {
          &:first-child {
            width: 40%;
          }
          &:nth-child(2) {
            width: 15%;
          }

          &:last-child {
            width:20%;
          }
        }
        @media (max-width: 316px) {
          &:first-child {
            width: 40%;
          }
          &:nth-child(2) {
            width: 20%;
          }

          &:last-child {
            width:20%;
          }
        }
      }

      .qna-reply-button {
        cursor: pointer;
        margin-left: 5px;
        border: 1px solid grey;
        padding: 2px 4px;
        border-radius: 5px;
        margin-left: 10px;
        &:hover {
          background-color: grey;
          color: white;
        }
      }
    }
    .bottompagination {
      display: flex;
      justify-content: center;
    }
  }
}

.searchBox {
  vertical-align: bottom;
  display: flex;
  select {
    margin-right: 1rem;
    height: 37px;
    padding-right: 15px;
  }
  input {
    margin-right: 10px;
    border: 1px solid #c1c1c1;
    height: 37px;
    border-radius: 0;
  }
  .searchbtn {
    border: 0;
    background-color: var(--color-primary);
    width: 150px;
    height: 37px;
    border-radius: 2px;
    color: white;
    text-align: center;
    vertical-align: bottom;
    cursor: pointer;
  }
}

.pagination {
  .page-item {
    &.disabled {
      background-color: white;
    }
    &.active {
      border-color: grey;
      color: white;
      background-color: var(--color-primary);
      .page-link {
        background-color: var(--color-primary);
        color: white;
      }
    }
    .page-link {
      color: black;
      border-color: var(--color-border);
      background: white;
      &.active {
        border-color: grey;
        color: white;
        background-color: var(--color-primary);
        .page-link {
          background-color: var(--color-primary);
        }
      }
      &:hover {
        //border-color:  grey;
        color: white;
        background-color: var(--color-primary);
      }
    }
  }
}

.qnaboardDetail {
  max-width: 1280px;
  margin: 0 auto;
  .qnaboardDetail-wrap {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    line-height: 2.4rem;
    .qnaboardDetail {
      strong {
        font-size: 18px;
      }
      tbody {
        tr {
          color: black;
          border-bottom: 1px solid var(--color-lightgrey);
          &:nth-child(3) {
            min-height: 150px;
          }
        }
        td {
          font-size: 15px;
          padding: 5px 10px;
        }
      }
      thead {
        color: var(--color-dark);
        height: 80px;
        // background-color: var(--color-primary);
        text-align: center;
        border-top: 1px solid var(--color-dark);
        border-bottom: 1px solid var(--color-lightgrey);
        padding: 10px 20px;
        td {
          text-align: center;
        }
      }
    }
  }
}
