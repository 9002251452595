// .gallery-wrap{
//   width: 95%;
//   margin: 0 auto;
// }
// .board strong {
//   font-size: 1.1rem;
// }
.gallery-container {
  max-width: 1280px;
  margin: 0 auto;
  .gallery-wrap {
    width: 95%;
    margin: 0 auto;
    .gallery-thumbnail {
      width: 100%;
      height: 350px;
      object-fit: cover;
    }
    .boardwrite {
      text-align: right;
      margin-bottom: 0.8rem;

      .boardwrite-btn {
        border: 0;
        background-color: var(--color-primary);
        padding: 8px 15px;
        border-radius: 2px;
        color: white;
        text-align: center;
        vertical-align: bottom;
        cursor: pointer;
      }
    }
  }
  .boardBottom {
    display: flex;
    justify-content: space-between;
  }
}
