/*=====================
    Header Area 
=======================*/

.header-area {
  .header-wrapper {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0 7px 0;
    flex-wrap: wrap;

    @media #{$sm-layout} {
      padding: 10px 0;
    }
    @media #{$md-layout} {
      padding: 10px 0;
    }
    @media #{$lg-layout} {
      padding: 10px 0;
    }
    .header-hamberger {
      display: flex;
      align-items: center;
      .hamberger {
        margin-right: 27px;
      }
    }
    a {
      &.rn-btn {
        &.theme-color {
          border-color: var(--color-primary);
          color: var(--color-primary);
        }
        padding: 0 25px;
        height: 46px;
        display: inline-block;
        line-height: 42px;
        border: 2px solid #b1b4c1;
        border-radius: 6px;
        color: #c6c9d8;
        font-size: 14px;
        @extend %transition;
        position: relative;
        z-index: 2;
        @media #{$sm-layout} {
          padding: 0 11px;
          height: 39px;
          line-height: 33px;
          font-size: 12px;
        }
        @media #{$large-mobile} {
          height: 34px;
          line-height: 29px;
        }
        &::before {
          display: none;
        }
        &:hover {
          border-color: transparent;
          transform: translateY(-3px);
          color: #ffffff;
          border: 2px solid var(--color-primary);
          background: var(--color-primary);
          border-radius: 6px;
        }
        @media #{$sm-layout} {
          display: none;
        }
      }
    }

    .logo {
      a {
        img {
          width: 200px;
          // padding-bottom: 10px;
          //@media #{$md-layout} {
          // @media (max-width : 1128px) {
          //     width: 160px;
          //     margin-top: 5px;
          //     margin-bottom: 0;
          // }
          @media #{$sm-layout} {
            width: 120px;
            margin-top: 8px;
            margin-bottom: 0;
          }
        }
      }
    }
    .header-left {
      display: flex;
    }
    nav {
      margin-left: 40px;
    }
  }
  &.color-white {
    a {
      &.rn-btn {
        border: 2px solid #ffffff;
        color: #ffffff;
      }
    }
  }

  &.color-black {
    a {
      &.rn-btn {
        border: 2px solid rgba(29, 29, 36, 0.5);
        color: $body-color;
      }
    }
  }

  // Static Header
  // &.header--static {
  //     padding-left: 50px;
  //     padding-right: 50px;
  //     padding-top: 15px;
  //     padding-bottom: 15px;

  //     @media #{$sm-layout} {
  //         padding-left: 15px;
  //         padding-right: 15px;
  //     }
  //     .header-wrapper {
  //         padding: 7px 0;
  //         .logo {
  //             a{
  //                 img{
  //                     @media #{$sm-layout} {
  //                         width: auto;
  //                     }
  //                 }
  //             }
  //         }
  //         a ,
  //         button {
  //             &.btn-default {
  //                 padding: 13px 30px;
  //             }
  //         }
  //     }
  // }

  // Header Fixed
  &.header--fixed {
    position: absolute;
    top: 0;
    z-index: 800;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
    // background: black;
    transition: 0.3s;

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    @media #{$sm-layout} {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .header-wrapper {
      // padding: 20px 0;
      transition: 0.3s;
      .logo {
        a {
          img {
            transition: 0.5s;
            &.logo-1 {
              display: block;
            }
            &.logo-2 {
              display: none;
            }
          }
        }
      }
      .mainmenunav {
        ul {
          &.mainmenu {
            > li {
              > a {
                position: relative;
                font-size: 19px;
                font-weight: bold;
                &::after {
                  position: absolute;
                  content: "";
                  left: 0;
                  width: 0;
                  height: 3px;
                  background: var(--color-primary);
                  transition: 0.3s;
                  border-width: 10px 10px 0 10px;
                  bottom: 0;
                }
                @media (max-width: 1128px) {
                  font-size: 18px;
                }
              }
              &.is-current {
                a {
                  color: var(--color-primary);
                  &::after {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.default-color {
      &.sticky {
        .mainmenunav {
          ul {
            &.mainmenu {
              > li {
                > a {
                  color: $heading-color;
                }
                &.is-current {
                  a {
                    color: var(--color-primary);
                    &::after {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.sticky {
      position: fixed;
      background: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      .header-wrapper {
        padding: 0px;
        a {
          &.rn-btn {
            color: $heading-color;
            border-color: $heading-color;
            &:hover {
              color: #ffffff;
              border-color: var(--color-primary);
            }
          }
        }
      }
      .logo {
        a {
          img {
            height: 50px;
            width: auto;
            &.logo-1 {
              display: none;
            }
            &.logo-2 {
              display: block;
            }
          }
        }
      }
      .humberger-menu {
        span {
          &.text-white {
            color: $heading-color !important;
          }
        }
      }
    }
  }

  &.header-not-transparent {
    &.header--fixed {
      position: static;
      padding-left: 0;
      padding-right: 0;
      padding: 0;
      &.sticky {
        position: fixed;
        background: #ffffff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 12px 0;
      }
    }
  }

  &.header--transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
    z-index: 800;
    // .header-wrapper {
    // }
    @media #{$lg-layout} {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 0;
      margin-top: 0;
    }
    @media #{$sm-layout} {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 0;
      margin-top: 0;
    }
    @media #{$large-mobile} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.sticky,
  &.header--static,
  &.color-black {
    a {
      &.btn-default {
        &.btn-border {
          &.btn-opacity {
            color: #1d1d24;
            border-color: #1d1d24;
            &:hover {
              color: #ffffff;
              border-color: var(--color-primary);
            }
          }
        }
      }
    }
  }

  &.sticky {
    a,
    button {
      &.btn-default {
        padding: 10px 27px;
        @media #{$sm-layout} {
          padding: 7px 16px;
        }
      }
    }
  }

  &.color-black {
    .humberger-menu {
      span {
        &.text-white {
          color: $heading-color !important;
        }
      }
    }
  }

  &.logoresize {
    .logo {
      a {
        img {
          height: 58px;
          width: auto !important;
          @media #{$large-mobile} {
            height: auto;
          }
        }
      }
    }
  }

  .logo {
    a {
      display: block;
      img {
        max-height: 60px;
      }
    }
  }

  a {
    &.btn-default {
      @media #{$lg-layout} {
        font-size: 14px;
        letter-spacing: 0;
        padding: 10px 27px;
      }
      @media #{$sm-layout} {
        font-size: 14px;
        letter-spacing: 0;
        padding: 7px 16px;
      }
    }
  }
}

.header-right {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 3;
  flex-wrap: wrap;
  .header-btn {
    margin-left: 50px;
    @media #{$lg-layout} {
      margin-left: 30px;
    }
    @media #{$large-mobile} {
      margin-left: 0;
    }
  }
  .header_login {
    li {
      list-style: none;
      margin-left: 10px;
      margin-bottom: 5px;
      a {
        color: white;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
}

/*-----------------------------
    MObile Menu Style Css 
-------------------------------*/

.header-area {
  .header-wrapper {
    .mainmenunav {
      @media #{$md-layout} {
        position: absolute;
      }
      @media #{$sm-layout} {
        position: absolute;
      }
      ul {
        &.mainmenu {
          @media (min-width: 2200px) {
            width: 800px;
          }
          @media only screen and (max-width: 2200px) and (min-width: 1130px) {
            width: 40vw;
            justify-content: space-evenly;
          }
          @media #{$md-layout} {
            width: 60vw;
            height: 100vh;
            position: fixed;
            top: 0;
            right: -61vw;
            z-index: 99;
            padding: 55px;
            background: #ffffff;
            -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
            -o-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
            transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
            display: block;
            overflow-y: auto;
          }
          @media #{$sm-layout} {
            width: 60vw;
            height: 100vh;
            position: fixed;
            top: 0;
            right: -61vw;
            z-index: 99;
            padding: 55px;
            background: #ffffff;
            -webkit-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
            -o-transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
            transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
            display: block;
            overflow-y: auto;
          }

          li {
            @media #{$md-layout} {
              margin: 0;
              padding: 10px 0;
            }

            @media #{$sm-layout} {
              margin: 0;
              padding: 15px 0;
            }
            &.has-droupdown {
              .accordion {
                border: none;
                .accordion-item {
                  border: none;
                }
                .accordion-button {
                  background-color: white !important;
                  border: none;
                  box-shadow: none;
                  font-size: 14px;
                  padding: 12px 20px 7px 30px;
                  //color: #1f1f25;
                  color: $heading-color;
                  font-weight: 600;

                  font-family: "font_M", sans-serif;

                  @media #{$md-layout} {
                    padding: 10px 0px 7px 20px;
                  }
                  @media (max-width: 770px) {
                    padding: 10px 0px 7px 20px;
                  }
                  @media #{$large-mobile} {
                    width: 90%;
                  }
                  @media (max-width: 429px) {
                    width: 80%;
                  }
                  @media (max-width: 380px) {
                    width: 70%;
                  }
                  @media (max-width: 353px) {
                    width: 60%;
                  }
                  @media (max-width: 300px) {
                    width: 50%;
                  }
                  &::after {
                    padding: 0px;
                  }

                  &:focus {
                    box-shadow: none;
                  }

                  &:not(.collapsed) {
                    color: #1f1f25;
                    box-shadow: none !important;
                    &::after {
                      background-size: 15px 15px;
                    }
                    background-position: center;
                  }
                  &::after {
                    background-position: center;
                    background-size: 15px 15px;
                  }
                }
                .accordion-body {
                  padding: 0 0 0 12px;
                  border: none;
                }
              }
              @media #{$md-layout} {
                position: relative;
              }

              @media #{$sm-layout} {
                position: relative;
              }

              > a {
                @media #{$sm-layout} {
                  position: relative;
                  &::after {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 7px 0 7px;
                    border-color: #1f1f25 transparent transparent transparent;
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    background: transparent;
                    left: auto;
                  }
                  &.open {
                    color: var(--color-primary);
                    &::after {
                      width: 0;
                      height: 0;
                      border-style: solid;
                      border-width: 0 10px 10px 10px;
                      border-color: transparent transparent var(--color-primary) transparent;
                    }
                  }
                }
                @media #{$md-layout} {
                  position: relative;
                  &::after {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10x 10px 0 10px;
                    border-color: #1f1f25 transparent transparent transparent;
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    background: transparent;
                    left: auto;
                  }
                  &.open {
                    color: var(--color-primary);
                    &::after {
                      width: 0;
                      height: 0;
                      border-style: solid;
                      border-width: 0 10px 10px 10px;
                      border-color: transparent transparent var(--color-primary) transparent;
                    }
                  }
                }
              }
            }
            a {
              @media #{$md-layout} {
                padding: 0;
                display: block;
              }
              @media #{$sm-layout} {
                padding: 0;
                display: block;
              }
            }
            ul {
              &.submenu {
                @media #{$md-layout} {
                  position: relative;
                  height: 0;
                  transition: 0.3s;
                  padding: 0;
                  opacity: 0;
                  visibility: hidden;
                  background: transparent;
                  box-shadow: inherit;
                }
                @media #{$sm-layout} {
                  position: relative;
                  height: 0;
                  transition: 0.3s;
                  padding: 0;
                  opacity: 0;
                  visibility: hidden;
                  background: transparent;
                  box-shadow: inherit;
                }
                li {
                  @media #{$md-layout} {
                    padding: 3px 0;
                  }
                  @media #{$sm-layout} {
                    padding: 3px 0;
                  }
                  a {
                    @media #{$md-layout} {
                      padding: 2px 10px;
                    }
                    @media #{$sm-layout} {
                      padding: 2px 10px;
                    }
                  }
                }
                &.active {
                  @media #{$md-layout} {
                    height: 100%;
                    padding: 12px 0;
                    opacity: 1;
                    visibility: visible;
                  }
                  @media #{$sm-layout} {
                    height: 100%;
                    padding: 12px 0;
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
            a {
              font-weight: 600;

              @media #{$md-layout} {
                color: $heading-color;
                font-weight: 600;
              }
              @media #{$sm-layout} {
                color: $heading-color;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .close-menu {
      position: fixed;
      top: 12px;
      opacity: 0;
      visibility: hidden;
      right: 17px;
      z-index: -10;
    }
    &.menu-open {
      .close-menu {
        opacity: 1;
        visibility: visible;
        z-index: 9999;
        span {
          color: $black-color;
          font-size: 24px;
          cursor: pointer;
        }
      }
      .mainmenunav {
        ul {
          &.mainmenu {
            @media #{$md-layout} {
              right: -1px;
            }
            @media #{$sm-layout} {
              right: -1px;
            }
            @media #{$large-mobile} {
              width: 60vw;
              padding: 54px 20px;
              overflow-x: hidden;
            }
          }
        }
      }
    }
  }
  // &.header-style-two {
  //     .header-wrapper {
  //         @media #{$md-layout} {
  //             padding: 30px 0;
  //         }
  //         @media #{$sm-layout} {
  //             padding: 15px 0;
  //         }
  //         .logo {
  //             a{
  //                 img {
  //                     @media #{$md-layout} {
  //                         width: auto;
  //                     }
  //                     @media #{$sm-layout} {
  //                         width: auto;
  //                     }
  //                 }
  //             }
  //         }
  //         .mainmenunav{
  //             ul {
  //                 &.mainmenu {
  //                     li {
  //                         a {

  //                         }
  //                     }
  //                 }
  //             }
  //         }

  //         ul {
  //             &.social-share {
  //                 li{
  //                     @media #{$sm-layout} {
  //                         margin: 0;
  //                     }
  //                 }
  //             }
  //         }

  //     }
  // }
}

// .header-style-two{
//     .header-right {
//         .header-btn {
//             @media #{$lg-layout} {
//                 margin-left: 7px;
//             }
//         }
//     }
// }
/*------------------------
    Hamberger Menu  
---------------------------*/
.humberger-menu {
  span {
    cursor: pointer;
    @media #{$sm-layout} {
      font-size: 28px;
    }
    @media #{$md-layout} {
      font-size: 28px;
    }
  }
}
.header-wrapper.menu-open .humberger-menu span {
  opacity: 0;
}
