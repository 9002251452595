/*=====================
    Pagination Styles 
=======================*/

.rn-pagination{
    ul{
        &.page-list{
            @extend %liststyle;
            li {
                display: inline-block;
                padding: 5px;
                a {
                    padding: 0 10px;
                    display: block;
                    text-align: center;
                    line-height: 38px;
                    min-width: 42px;
                    height: 42px;
                    border: 2px solid var(--color-border);
                    background: #fff;
                    color: var(--color-border);
                    position: relative;
                    z-index: 2;
                    font-weight: 600;
                    border-radius: 4px;
                    &:hover {
                        color: #ffffff;
                        border-color: var(--color-primary);
                        background-color: var(--color-primary);
                    }
                }
                // .page-link {
                //     padding: 0 10px;
                //     display: block;
                //     text-align: center;
                //     line-height: 38px;
                //     min-width: 42px;
                //     height: 42px;
                //     border: 2px solid var(--color-border);
                //     background: #fff;
                //     color: var(--color-border);
                //     position: relative;
                //     z-index: 2;
                //     font-weight: 600;
                //     border-radius: 4px;
                //     &:hover {
                //         color: #ffffff;
                //         border-color: var(--color-primary);
                //         background-color: var(--color-primary);
                //     }
                //     &.active{
                //         a {
                //             color: #ffffff;
                //             border-color: var(--color-primary);
                //             background-color: var(--color-primary);
                //         }
                //     }
                // }
                &.active{
                    a {
                        color: #ffffff;
                        border-color: var(--color-primary);
                        background-color: var(--color-primary);
                    }
                }
            }
        }
    }
}
.line-separator {
    border-bottom: 1px solid #ebebeb;
}



