.map-container{
    max-width: 1280px;
  
    margin: 0 auto;
    .wrapper{
    width: 90%;
    margin:0 auto;
    @media (max-width:500px) {
        width: 100%;
    }
    h5{
        margin-top: 18px;
    }
    .map-right{

        .addressp{
            font-size: 20px;
        }
    }
    p{
        margin:0;
        color: var(--color--grey)
    }
}
}