.timetables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .tab-btn {
    min-width: 120px;
    margin: 5px 5px;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }
  .activeTab {
    background-color: var(--color-primary);
    color: white;
    border: 1px solid var(--color-primary);
  }
}
