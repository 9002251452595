.react-datepicker__aria-live {
    display: none;
  }

.react-datepicker__input-container input{
    border-radius: 20px;
    font-size: 18px;
     width: 100%;
    width: 500px;
    @media #{$lg-layout} {
        width: 300px;
    }
    @media (max-width: 767px){
        width: 300px;
      }
    @media (max-width: 451px){
        width: 200px;
    }
    @media (max-width: 336px){
        width: 100%;
    }
    // @media #{$md-layout} {
    //     width: 100%;
    // }
    // @media #{$sm-layout} {
    //     width: 200%;
    // }
    &::placeholder{
        font-size: 15px;
        // width: 100%;
        @media (max-width: 336px){
            font-size: 12px;
        }
    }
}
.rasta-stripes {
    .react-datepicker__month-container{
        background-color: white;
    }
}


.normal-day {
    background: white !important;
    color: black !important;
    width: 28px;
    height: 28px;
    line-height: 1.8;
    text-align: center;
    padding: 2px !important;
}

.selected-day {
    background: var(--color-primary) !important;
    color: white !important;
    border-radius: 30% !important;
    font-weight: 700;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    background: var(--color-primary) !important;
}


.react-datepicker__close-icon{
    margin-right: 10px;
    &::after{
        background: var(--color-primary) !important; 
    }
}


