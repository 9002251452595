/*-----------------------------
    Portfolio Details Styles 
-------------------------------*/

.portfolio-thumb-inner {
  .thumb {
    position: relative;
    .video-popup {
      position: absolute;
      top: 50%;
      left: 50%;
      border: 0 none;
      width: 110px;
      height: 110px;
      background: var(--color-primary);
      margin-left: -35px;
      margin-top: -35px;

      @media #{$sm-layout} {
        width: 70px;
        height: 70px;
      }

      &::before {
        border-left: 15px solid #ffffff;
      }
      span {
        font-size: 18px;
        line-height: 28px;
        display: inline-block;
        color: #292642;
        font-weight: 500;
      }
      &:hover {
        transform: scale3d(1.15, 1.15, 1.15);
      }
    }
  }
}

.portfolio-details-accordion {
  .inner {
    border: 1px solid #e1e1e1;
    padding: 15px 25px;
    border-radius: 10px;
  }
}

.portfolio-details {
  .inner {
    img {
      width: 90%;
    }
    h2 {
      font-size: 35px;
      line-height: 44px;
      display: block;
      margin-bottom: 0;
      padding-bottom: 40px;
      border-bottom: 1px solid #e1e1e1;
      @media #{$sm-layout} {
        font-size: 30px;
        line-height: 45px;
      }
    }
    .use-title {
      font-size: 35px;
      line-height: 44px;
      display: block;
      margin-bottom: 0;
      // padding-bottom: 40px;
      // border-bottom: 1px solid #e1e1e1;
      @media #{$sm-layout} {
        font-size: 30px;
        line-height: 45px;
      }
    }
    .list-style--1 {
      @extend %liststyle;
      li {
        color: #464646;
        font-weight: 400;
        font-size: 17px;
        line-height: inherit;
        margin-bottom: 10px;
        @media (max-width: 436px) {
          font-size: 15px;
        }
        i,
        svg {
          color: var(--color-primary);
          margin-right: 5px;
        }
      }

      select {
        width: 195px;
        height: 38px;
        margin: 0px 10px 0 10px;
        // @media(max-width:538px){
        //     width: 40%;
        // }
      }
      .member-input {
        width: 80px;
        height: 35px;
        margin: 0px 10px;
        border-radius: 0px;
      }
      input {
        border-radius: 0px;
      }
      .others-input {
        width: 35%;
        border-radius: 0;
        @media only screen and (min-width: 990px) and (max-width: 1200px) {
          margin-top: 10px;
          width: 100%;
        }
        @media (max-width: 504px) {
          margin-top: 10px;
          width: 100%;
        }
      }
      .check_hope {
        width: 20px;
        // margin:0 10px;
      }
      .accompanyName {
        width: 82%;
        box-sizing: border-box;
        margin-left: 14px;
        @media (max-width: 1200px) {
          width: 77%;
        }
        @media (max-width: 990px) {
          width: 86%;
        }
        @media (max-width: 769px) {
          width: 80%;
        }
        @media (max-width: 534px) {
          width: 78%;
        }
        @media (max-width: 493px) {
          width: 70%;
        }
        @media (max-width: 396px) {
          width: 60%;
        }
        @media (max-width: 280px) {
          width: 50%;
        }
      }
      .time-btn {
        padding: 8px 5px;
        border-radius: 5px;
        margin: 5px 3px;

        &:hover {
          background-color: var(--color-primary);
          color: white;
          border: 1px solid var(--color-primary);
        }
        &.selected {
          background-color: var(--color-primary);
          color: white;
          border: 1px solid var(--color-primary);
        }
      }
    }
    .chk {
      width: 20px;
      margin-bottom: 15px;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      color: rgba(29, 29, 36, 0.75);
      margin-bottom: 30px;
      br {
        display: block;
      }
      @media #{$lg-layout} {
        margin-bottom: 22px;
      }

      @media #{$md-layout} {
        margin-bottom: 22px;
      }

      @media #{$sm-layout} {
        margin-bottom: 22px;
      }
      &.subtitle {
        color: $heading-color;
        font-size: 23px;
        line-height: 44px;
        margin-top: 28px;

        @media #{$lg-layout} {
          font-size: 20px;
          line-height: 38px;
          margin-top: 24px;
        }
        @media #{$md-layout} {
          font-size: 18px;
          line-height: 38px;
          margin-top: 24px;
        }
        @media #{$sm-layout} {
          font-size: 18px;
          line-height: 38px;
          margin-top: 24px;
        }
      }
    }
    .portfolio-view-list {
      margin: 0 -40px;
      .port-view {
        margin: 0 40px;
        span {
          font-size: 16px;
          color: rgba(29, 29, 36, 0.75);
          display: block;
          margin-bottom: 10px;
          font-weight: 500;
        }
        h4 {
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }
}

.portfolio-related-work {
  .mainboard-container {
    &fade-in {
      opacity: 0;
      transform: translateY(20px); /* 아래에서 위로 20px 이동 */
      transition: opacity 0.5s ease, transform 0.5s ease;
    }

    &fade-in.active {
      opacity: 1;
      transform: translateY(0);
    }
    .announcement {
      margin: 55px 15px;
      line-height: 43px;
      @media (max-width: 769px) {
        margin: 55px 15px;
        line-height: 33px;
      }
      .announcement-title {
        margin-bottom: 15px;
        @media (max-width: 769px) {
          margin-bottom: 10px;
        }
      }
    }
    .freeboard {
      margin: 30px 15px;
    }
    .color-title {
      font-size: 35px;
      font-family: "font_B";
      // font-weight: 900;
      @media (max-width: 1200px) {
        font-size: 26px;
      }
      @media #{$sm-layout} {
        font-size: 22px;
      }
      @media (max-width: 400px) {
        font-size: 22px;
      }
    }
    .title-des {
      font-size: 20px;
      font-weight: 400;
      color: #565656;
    }
    span {
      font-size: 20px;
      color: black;
      @media (max-width: 1200px) {
        font-size: 17px;
      }
      @media #{$sm-layout} {
        font-size: 15px;
      }
      @media (max-width: 400px) {
        font-size: 13px;
      }
    }
    .titleFont {
      font-family: "eng_M";
    }
  }
}
// Related Project
.related-work {
  .thumb {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* 반응형 그리드 */
    // grid-gap: 15px; /* 그리드 아이템 간격 조절 */
    // overflow-y: auto; /* 스크롤이 필요한 경우 스크롤 표시 */

    // width: 100%;
    height: 100%;
    // max-height: 480px; /* 최대 높이 설정 - 넘치면 스크롤 표시 */
    // overflow-y: scroll;
    margin: 0 auto;
    // @media(max-width: 1200px){
    //     max-height: 380px;
    // }
    // @media(max-width: 991px){
    //     max-height: 580px;
    // }
    // @media(max-width: 768px){
    //      width: 90%;
    //      max-height: 490px;
    // }

    //     @media(max-width: 545px){
    //         width: 90%;
    //         max-height: 480px;
    //    }
    //    @media(max-width: 537px){
    //     width: 90%;
    //     max-height: 470px;
    //   }
    //   @media(max-width: 519px){
    //     width: 90%;
    //     max-height: 460px;
    //   }
    //   @media(max-width: 508px){
    //     width: 90%;
    //   }
  }
  .inner {
    padding-top: 25px;
    h4 {
      font-size: 24px;
      line-height: 39px;
      margin-bottom: 7px;
      a {
        color: $heading-color;
        @extend %transition;
      }
      &:hover {
        a {
          color: var(--color-primary);
        }
      }
    }
    span {
      &.category {
        color: rgba(29, 29, 36, 0.75);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
