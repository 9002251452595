.signInWrap{
    padding: 120px 0 100px 0;

    .signIn-box {
        padding: 4rem 0;

        .signIn-container{
            padding: 20px 50px;
            border-radius: 5px;
            width: 470px;
            margin: 0 auto;  
  
            @media (max-width: 500px) {
              width: 100%;
            }
          }
          .signHead {
            margin-top: 15px;
            margin-bottom: 15px;
            font-size: 25px;
            font-weight: bold;
            color:var(--color-dark);
            text-align: center;
            font-size: 2rem;
          }

          input {
            outline: 0;
            border: 1px solid var(--color-input);
            margin-top: 7px;
            margin-bottom: 8px;
            border-radius: 4px;
            width: 100%;
            padding: 9px 8px;
            @media (max-width: 500px) {
              padding: 2px 5px;
            }
            &::placeholder {
                color: grey;
                font-size: 12px;
                @media (max-width: 500px) {
                  font-size: 12px;
                }
              }
           
          }
          .select-belong{
            padding-left:5px;
            border: 1px solid var(--color-input);
            margin-top: 7px;
            margin-bottom: 8px;
            border-radius: 4px;
            option[disabled]{
              color:grey;
            }
          }
          .errorMessage{
            font-size: 13px;
            color: red;
          }
          .registerBtn {
            width: 100%;
            background-color: var(--color-dark);
            color: white;
            padding: 12px 16px;
            font-size: 18px;
            border-radius: 3px;
            cursor: pointer;
            margin-top: 10px;
            margin-bottom: 15px;
          }
          
         a{
            font-size: 1rem;
            color: rgb(87, 87, 87);
            text-decoration:none;
            float: right;
            // font-size: 14px;
         }
          
      }

}

  
 

  
