/*----------------------
Equipments Details Styles 
------------------------*/

.blog-single-page-title {
  position: relative;
  @media (min-width: 991px) {
    padding-top: 71px;
    padding-bottom: 40px;
  }
  @media (max-width: 991px) {
    padding-top: 100px;
  }
  h2 {
    font-weight: bold;
    //line-height: 90px;
    font-size: 40px !important;
    margin-top: 0;
    @media #{$sm-layout} {
      line-height: 52px;
      font-size: 30px !important;
    }
    // @media (max-width: 500px) {
    //   font-size: 30px !important;
    // }
  }
  ul {
    &.blog-meta {
      @extend %liststyle;
      margin: 0 -15px;
      flex-wrap: wrap;
      position: absolute;
      width: 100%;
      bottom: -135px;
      li {
        font-size: 16px;
        color: #c6c9d8;
        margin: 0 15px;
        margin-bottom: 13px;
        @media #{$sm-layout} {
          font-size: 14px;
        }
        svg {
          margin-right: 13px;
          font-size: 22px;
        }
      }
    }
  }
}
.rn-blog-details {
  .inner-wrapper {
    .inner {
      padding: 100px 0;
      @media #{$lg-layout} {
        padding: 80px 40px;
      }
      @media #{$md-layout} {
        padding: 50px 40px;
      }
      @media #{$sm-layout} {
        padding: 50px 10px;
      }
      p {
        font-size: 16px;
        line-height: 30px;
        color: rgba(29, 29, 36, 0.75);

        br {
          display: block;
        }
        @media #{$sm-layout} {
          font-size: 14px;
        }
        a {
          color: rgba(29, 29, 36);
          font-size: 19px;
          @media #{$sm-layout} {
            font-size: 15px;
          }
          &:hover {
            color: var(--color-primary);
          }
        }

        .emailAtag {
          font-size: 14px;
          line-height: 30px;
          color: rgba(29, 29, 36, 0.75);

          &:hover {
            color: var(--color-primary);
          }
        }
        @media #{$sm-layout} {
          font-size: 13px;
        }
      }
      .thumbnail {
        text-align: center;
        // padding-right: 0 !important;
        margin: 0;

        @media #{$sm-layout} {
          margin: 0;
        }
        @media #{$md-layout} {
          margin: 0;
        }
        img {
          max-width: 500px;
        }
      }
      .rn-blog-quote {
        font-size: 24px;
        line-height: 40px;
        color: $heading-color;
        font-weight: 400;
        text-align: center;
        padding: 0 9%;
        margin-top: 67px;
        margin-bottom: 67px;
        position: relative;
        z-index: 2;

        @media #{$lg-layout} {
          padding: 0 4%;
          margin-top: 40px;
          margin-bottom: 40px;
        }
        @media #{$md-layout} {
          padding: 0 4%;
          margin-top: 40px;
          margin-bottom: 40px;
        }

        @media #{$sm-layout} {
          padding: 0 4%;
          margin-top: 40px;
          margin-bottom: 40px;
          font-size: 20px;
          line-height: 33px;
        }
        @media #{$large-mobile} {
          padding: 0;
          margin-top: 20px;
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 29px;
        }

        &::before {
          position: absolute;
          width: 234px;
          height: 121%;
          left: 50%;
          top: 2px;
          background-image: url(/assets/images/client/image-1.png);
          content: "";
          opacity: 1;
          background-repeat: no-repeat;
          z-index: -1;
          transform: translateX(-50%);
        }
      }
      .equip-essential {
        width: 100%;
        tbody {
          td {
            &:nth-child(2) {
              @media (max-width: 491px) {
                width: 15%;
              }
              @media (max-width: 332px) {
                width: 20%;
              }
            }
            &:last-child {
              padding: 0px;
            }
          }
          @media (max-width: 491px) {
            font-size: 15px;
          }
        }
        .rn-btn {
          @media (max-width: 1000px) {
            font-size: 13px;
            padding: 3px 5px !important;
          }
          @media #{$sm-layout} {
            font-size: 13px;
          }
          @media (max-width: 328px) {
            font-size: 15px;
          }
          @media (max-width: 284px) {
            font-size: 12px;
            padding: 3px 7px;
          }
        }
      }
      .equip-purpose {
        td {
          @media (max-width: 485px) {
            font-size: 15px;
          }
        }
        .device-btn {
          &.selected {
            background-color: var(--color-primary);
            border: 1px solid var(--color-primary);
            color: white;
          }
        }
        td:first-child {
          width: 10%;
          @media only screen and (max-width: 1200px) and (min-width: 990px) {
            width: 20%;
          }
          @media (max-width: 990px) {
            width: 40%;
            font-size: 16px;
          }
          // @media #{$sm-layout} {
          //   width: 20%;
          // }
          @media (max-width: 420px) {
            width: 30%;
          }
          @media (max-width: 359px) {
            font-size: 15px;
            width: 25%;
          }
          @media (max-width: 300px) {
            font-size: 15px;
            width: 30%;
          }
        }
        .equip-purpose-select {
          width: 500px;
          @media #{$lg-layout} {
            width: 300px;
          }
          @media (max-width: 767px) {
            width: 300px;
          }
          @media (max-width: 451px) {
            width: 200px;
          }
          @media (max-width: 336px) {
            width: 100%;
          }
          // @media #{$sm-layout} {
          //     width: 120%;
          // }
        }
        select {
          padding: 5px;
        }
        input {
          border-radius: 0px !important;
        }
        .time-btn {
          padding: 8px 5px;
          border-radius: 5px;
          margin: 5px;
          @media #{$md-layout} {
            font-size: 15x;
            margin: 1px 3px;
          }
          @media #{$sm-layout} {
            font-size: 14px;
            margin: 1px 2.5px;
          }
          @media (max-width: 380px) {
            font-size: 11px;
            margin: 1px 2px;
          }
          @media (max-width: 310px) {
            font-size: 14px;
            margin: 1px 2px;
          }
          &.selected {
            background-color: var(--color-primary);
            color: white;
            border: 1px solid var(--color-primary);
          }
          &.disabled {
            background-color: var(--color-lightgrey);
            color: white;
            border: 1px solid var(--color-lightgrey);
            cursor: not-allowed;
          }
        }
        textarea {
          &::placeholder {
            padding-left: 10px;
            font-size: 15px;
          }
        }
      }
    }
    .chk {
      width: 20px;
      margin-top: 10px;
      @media (max-width: 769px) {
        margin-top: 20px;
      }
    }
    // .description{
    //   @media(max-width:500px){
    //    font-size: 13px;
    //   }
    // }
    .blog-single-list-wrapper {
      margin-right: 0;
      @media #{$sm-layout} {
        margin-right: 0;
      }

      @media #{$md-layout} {
        margin-right: 0;
      }
      .thumbnail {
        padding-right: 30px;
        margin-right: 0;
        @media #{$sm-layout} {
          width: 100%;
          padding-right: 0;
        }
        @media #{$md-layout} {
          width: 100%;
          padding-right: 0;
        }
        img {
          // width: 300px;
          width: 100%;
          box-shadow: none;
        }
        span {
          color: rgba(29, 29, 36, 0.75);
          font-size: 14px;
          display: block;
          text-align: left;
          padding-top: 10px;
          padding-left: 110px;
          @media #{$sm-layout} {
            font-size: 13px;
            padding-left: 0;
            margin-bottom: 23px;
          }
        }
      }
      h4 {
        &.title {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 30px;
          border-bottom: 1px solid;
          padding-bottom: 23px;
          width: 100%;
          @media #{$sm-layout} {
            margin-bottom: 16px;
          }
        }
      }
      .content {
        width: 100%;

        @media #{$sm-layout} {
          width: 100%;
        }
        @media #{$md-layout} {
          width: 100%;
        }
        ul {
          &.list-style {
            margin-bottom: 50px;

            @media #{$sm-layout} {
              margin-bottom: 25px;
            }
          }
        }
      }
      table {
        font-size: 18px;
        @media #{$sm-layout} {
          margin-bottom: 15px;
        }
        button {
          margin-right: 10px;
        }
        span {
          font-size: 13px;
        }
      }

      .reserveContent {
        box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        min-height: 400px;
        padding: 25px 30px 20px 30px;
        margin-top: 50px;
        width: 50%;
        @media (max-width: 1200px) {
          height: 500px;
        }

        @media #{$sm-layout} {
          margin-top: -20px;
          margin-bottom: 40px;
          height: 420px;
        }

        @media (max-width: 420px) {
          margin-top: -20px;
          margin-bottom: 40px;
          height: 500px;
        }
        .blog-btn {
          text-align: right;
          margin-bottom: 20px;
          // button{
          //     border: none;
          //     padding: 15px 30px;
          //     border-radius: 30px;
          //     background-color: var(--color-primary);
          // }
          // .btn-default{
          //     font-size: 20px;
          //     padding: 10px 20px;
          // }
        }
        .chk {
          width: 20px;
        }
      }
    }
  }
}

/*------------------------
    BLog Contact Form  
--------------------------*/
.blog-comment-form {
  .inner {
    .rnform-group {
      margin-bottom: 20px;
      input {
        border: 2px solid #e9e9e9;
        border-radius: 4px;
        height: 50px;
        font-size: 16px;
      }
      textarea {
        min-height: 193px;
        border: 2px solid #e9e9e9;
        border-radius: 4px;
        resize: none;
        padding: 15px;
        font-size: 16px;
      }
    }
  }
}

/* modal */

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  width: 60%;
  padding: 50px;
  background-color: rgb(255, 255, 255);
  @media #{$sm-layout} {
    width: 80%;
    padding: 20px;
  }
  .modal-container {
    width: 100%;
    h5 {
      margin: 13px 0 5px 0;
      font-size: 19px;
      text-align: left;
    }
    h6 {
      margin: 0;
      font-size: 17px;
      text-align: left;
    }
    p {
      font-size: 13px;
      margin-bottom: 5px;
      text-align: left;
    }
    input {
      border-radius: 0;
    }
    textarea {
      height: 100px;
    }
  }
}
