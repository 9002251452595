.react-datepicker__aria-live {
    display: none;
  }
input{
    width: 100%;
    border-radius: 20px;
    font-size: 18px;
    &::placeholder{
        font-size: 15px;
    }
}

.normal-day {
    background: white !important;
    color: black !important;
    width: 28px;
    height: 28px;
    line-height: 1.8;
    text-align: center;
    padding: 2px !important;
    
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background: var(--color-primary) !important;
}
.today-day{
    background-color: white !important;
    color:black !important
}


.react-datepicker__close-icon{
    margin-right: 10px;
    &::after{
        background: var(--color-primary) !important; 
    }
}

.weekend-day{
    pointer-events: none;
}
.selected-day {
    background: var(--color-primary) !important;
    color: white !important;
    border-radius: 30% !important;
    font-weight: 700;
}

// .react-calendar .react-calendar__tile--now{
//     border: 1px solid blue;
//   border-radius: 50%;
// }