.boardInsert {
  max-width: 1280px;
  margin: 0 auto;
  .boarderInsertTable {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    line-height: 2.4rem;
    border-top: 1px solid black;
    tr {
      border-bottom: 1px solid var(--color-lightgrey);
      // height: 3.2rem;
    }

    td {
      &:first-child {
        // background-color: var(--color-tablegrey);
        width: 10%;
        font-weight: bold;
        padding-left: 1em;
        margin: 1rem;
        @media (max-width: 760px) {
          width: 15%;
        }
        @media (max-width: 500px) {
          width: 23%;
          font-size: 16px;
        }
      }
      strong {
        @media (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
    input {
      width: 98.5%;
      border: 1px solid var(--color-lightgrey);
      margin: 0.5rem;
      padding-left: 10px;
      border-radius: 0px;
    }

    strong {
      font-size: 1.1rem;
      color: var(--color-dark);
    }
    .QnaBoardcontent {
      padding: 0.5rem;
      height: 400px;
    }
    .privateCheckbox {
      float: left;
      height: 10px;
      .privateChk {
        width: 20px;
        margin: 0 8px;
      }
    }
  }
  .QNABoardBtnGrp {
    width: 95%;
    margin: 0 auto;
    text-align: right;
    margin-top: 2rem;

    button {
      border: 0;
      background-color: var(--color-primary);
      height: 2.4rem;
      padding: 0.37rem 1rem;
      border-radius: 2px;
      color: white;
      text-align: center;
      vertical-align: bottom;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  input[type="file"] {
    border: none;
  }
}

.QNABoardBtnGrp input::placeholder {
  font-size: 1rem;
  padding-left: 0.5rem;
}

.file-wrap {
  max-width: 100%;
}
.file-name {
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-cancle {
  border: 1px solid grey;
  padding: 2px 5px;
  margin-left: 20px;
  margin-bottom: 10px;
}
@media (max-width: 650px) {
  .boarderInsertTable td:first-child {
    // background-color: var(--color-tablegrey);
    width: 15%;
    font-weight: bold;
    padding-left: 1em;
    margin: 1rem;
    font-size: 10px;
  }
  /* .file-wrap{
    width: 90%;
    margin:0 auto;
    background-color: red;
  } */
}

@media (max-width: 400px) {
  .boarderInsertTable td:first-child {
    // background-color: var(--color-tablegrey);
    width: 18%;
    font-weight: bold;
    padding-left: 1em;
    margin: 1rem;
    font-size: 8px;
  }
  /* .file-wrap{
    width: 80%;
    background-color: red;
  } */
}
