.space-table {
  td:first-child {
    width: 10% !important;
  }
  select {
    padding: 5px;
  }
  input {
    border-radius: 0;
  }

  .time-btn {
    padding: 8px 5px;
    border-radius: 5px;
    margin: 5px 3px;
    @media #{$md-layout} {
      font-size: 13x;
      margin: 1px 3px;
    }
    @media (max-width: 300px) {
      font-size: 13px;
      margin: 1px 2px;
    }
    &.selected {
      background-color: var(--color-primary);
      color: white;
      border: 1px solid var(--color-primary);
    }
    &.disabled {
      background-color: var(--color-lightgrey);
      color: white;
      border: 1px solid var(--color-lightgrey);
      cursor: not-allowed;
    }
  
  }
  
}

// .timetables{
//     margin: 15px 0;
//     .time-btn{
//         padding: 5px 10px;
//         margin: 3px 5px;
//        @media #{$md-layout} {
//                    font-size: 13x;
//                    margin: 1px 3px;

//         }
//         @media (max-width:300px) {
//             font-size: 13px;
//             margin: 1px 2px;
//           }
//      &.selected{
//         background-color: var(--color-primary);
//         color: white;
//         border: 1px solid var(--color-primary);
//       }
//       &.disabled{
//         background-color: var(--color-lightgrey);
//         color: white;
//         border: 1px solid var(--color-lightgrey);
//         cursor: not-allowed;
//     }
//     }
// }
// .reserveText{
//     span{
//         @media #{$md-layout} {
//             font-size: 10x;
//              margin-left: 2px;
//         }

// }
// }

.space-sm-table {
  td:first-child {
    width: 10% !important;
    @media(max-width: 1200px){
      width: 20% !important;
    }
    @media(max-width: 530px){
      width: 30% !important;
    }
    @media (max-width: 485px) {
      font-size: 15px;
      width: 25%;
    }
  }
  input {
    border-radius: 0;
  }
  .time-btn {
    padding: 8px 5px;
    border-radius: 5px;
    margin: 5px 3px;
    @media #{$md-layout} {
      font-size: 13x;
      margin: 1px 3px;
    }
    @media #{$sm-layout}{
      font-size: 13px;
      margin: 1px 2px;
    }
    &.selected {
      background-color: var(--color-primary);
      color: white;
      border: 1px solid var(--color-primary);
    }
    &.disabled {
      background-color: var(--color-lightgrey);
      color: white;
      border: 1px solid var(--color-lightgrey);
      cursor: not-allowed;
    }
  }
  textarea{
    &::placeholder{
      padding-left: 13px; 
      font-size: 15px;
    }
  }
}
