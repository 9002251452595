.react-calendar {
  width: 100% !important;
  border: 1px solid var(--color-light) !important;
  box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.2);
  padding: 25px 15px;
  border-radius: 20px;
//   .react-calendar__navigation button {
//     background-color: white !important;
//   }
  .react-calendar__navigation__label {
    span {
      font-size: 25px;
      font-weight: bold;
      color: black;
    }
  }
  .react-calendar__tile:disabled{
    background-color: white ;
  }  
  .react-calendar__tile--now {
    background: var(--color-light) !important;
    &:enabled:hover,:enabled:focus{
        background-color: var(--color-primary) !important;
    }
  }
  .react-datepicker__day--keyboard-selected{
    background-color: var(--color-light) !important;
  }
  
//     .react-calendar__tile:enabled:hover,
//   .react-calendar__tile:enabled:focus {
//     background-color: var(--color-primary) !important;
//   }
  .react-calendar__tile {
    height: 100px;
    font-size: 22px;
 }
 .react-calendar__tile--active {
    background-color: var(--color-primary) !important;

 }
//   .react-calendar__tile:enabled:hover,
//   .react-calendar__tile:enabled:focus {
//     background-color: var(--color-primary) !important;
//   }
  .react-calendar__month-view__weekdays {
    abbr {
      /*월,화,수... 글자 부분*/
      color: grey;
      font-size: 20px;
      font-weight: 500;
      border-bottom: none;
      text-decoration: none;
    }
  }
}
