/*-------------------------
    Mainslide Area 
---------------------------*/

.transparent_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.main-area {
  max-width: 1280px;
  padding-top: 100px;
  @media (max-width: 1500px) {
    padding-top: 65px;
  }
  .main-slide {
    // position: relative;
    @extend %transition;
    min-height: 500px;
    width: 80%;
    padding-top: 8%;
    margin: 0 auto;
    @media (max-width: 1500px) {
      padding-top: 2%;
    }
    @media only screen and (min-width: 992px) and (max-width: 1500px) {
      min-height: 400px;
    }
    @media #{$md-layout} {
      min-height: 400px;
    }
    @media #{$sm-layout} {
      min-height: 400px;
    }
    .main-thumbnail-inner {
      transition: transform 0.28s ease;
      width: 75%;
      margin: 0 auto;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      cursor: pointer;
      @media #{$lg-layout} {
        width: 80%;
      }

      @media #{$sm-layout} {
        width: 85%;
      }
    }
    .main-thumbnail {
      height: 250px;
      @media (max-width: 1500px) {
        height: 250px;
        width: 100%;
        transform: scale(1.13) translateZ(0);
      }
      img {
        height: 250px;
        width: 100%;
        object-fit: cover;
      }
    }

    .main-content {
      height: 130px;
      text-align: center;
      width: 75%;
      margin: 0 auto;
      padding: 20px;
      background-color: #000;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      @media (max-width: 1500px) {
        height: 130px;
      }
      @media #{$lg-layout} {
        width: 80%;
        // right: 20px;
      }
      @media #{$md-layout} {
        // left: 20px;
        // right: 20px;
      }
      @media #{$sm-layout} {
        width: 85%;
      }
      .main-inner {
        position: relative;
        height: 100%;
        p {
          color: #c6c9d8;
          font-size: 14px;

          @media #{$md-layout} {
            // left: 20px;
            // right: 20px;
          }
          @media #{$sm-layout} {
            // left: 20px;
            // right: 20px;
          }
        }
        h4 {
          font-size: 15px;
          // line-height: 36px;
          // position: absolute;
          // top:18%;
          @media (max-width: 1200px) {
            font-size: 13px;
          }
          @media (max-width: 769px) {
            padding-left: 0;
          }
          a {
            color: #ffffff;
          }
        }
        .portfolio-button {
          @extend %transition;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
          @media #{$large-mobile} {
            margin-top: 25px;
          }
          @media #{$md-layout} {
            // left: 20px;
            // right: 20px;
          }
          @media #{$sm-layout} {
            // left: 20px;
            // right: 20px;
          }
          a {
            &.rn-btn {
              color: #ffffff;
              border-color: rgba(255, 255, 255, 0.3);
              padding: 1px 15px;
              @media #{$md-layout} {
                padding: 1px 15px;
              }
              @media #{$sm-layout} {
                padding: 1px 10px;
              }
              @media (max-width: 540px) {
                padding: 1px 5px;
              }
              &:hover {
                border-color: var(--color-primary);
              }
            }
          }
        }
      }
    }

    &.text-center {
      .content {
        position: absolute;
        bottom: 40px;
        left: 40px;
        // max-width:100% !important;
        z-index: 10;
        padding: 0;
        right: 40px;
        margin: 0 auto;
        // @media #{$lg-layout} {
        //     left: 20px;
        //     right: 20px;
        // }
        // @media #{$md-layout} {
        //     left: 15px;
        //     right: 15px;
        // }
        // @media #{$sm-layout} {
        //     left: 10px;
        //     right: 10px;
        // }
      }
    }
    &:hover {
      // .thumbnail-inner{
      //     transform: scale(1.08) translateZ(0);
      //     &::before{
      //         opacity: 0.85;
      //     }
      // }
      .thumbnail {
        transform: scale(1) translateZ(0);
        // &::after{
        //     background-color: rgba(25,25,25,.24);
        // }
      }
      .bg-blr-image {
        opacity: 0;
        z-index: 9;
      }
    }
    &.portfolio-style--2 {
      .inner {
        margin: 0 20px;
      }
    }
  }
}

.portfolio-sacousel-inner {
  .slick-list {
    margin-left: -200px;
    margin-right: -200px;
    @media #{$md-layout} {
      margin-left: 15px;
      margin-right: 15px;
    }
    @media #{$lg-layout} {
      margin-left: 15px;
      margin-right: 15px;
    }
    @media #{$sm-layout} {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.main-slick-dot {
  h3 {
    padding-top: 55px;
    font-size: 28px;
    font-weight: bold;
    padding-left: 70px;
    margin-bottom: 10px;
    color: white;
    @media (max-width: 1200px) {
      padding-left: 50px;
    }
    @media (max-width: 969px) {
      padding-top: 50px;
      padding-left: 35px;
      font-size: 24px;
    }
  }
  .slick-dots {
    left: 40%;
    bottom: 15px;
    // @media #{$large-mobile} {
    //     bottom: -60px;
    // }
    @media (max-width: 1500px) {
      left: 40%;
      bottom: -18px;
    }
    @media (max-width: 640px) {
      bottom: -20px;
      left: 35%;
    }
    @media (max-width: 540px) {
      bottom: -20px;
      left: 25%;
    }
    @media (max-width: 340px) {
      bottom: -20px;
      left: 18%;
    }
    li {
      width: 30px;
      height: 30px;
      margin: 0;
      opacity: 0.35;
      position: relative;
      display: inline-block;
      border-radius: 0;
      button {
        &::before {
          display: none;
        }
        &::after {
          background: #fff;
          width: 100%;
          display: block;
          content: "";
          position: absolute;
          left: 0;
          width: 10px;
          height: 10px;
          @extend %transition;
          // border-radius: 50%;
          margin-left: 11px;
          background-color: transparent;
          box-shadow: inset 0 0 0 5px #fff;
        }
      }
      &.slick-active {
        opacity: 1;
        button {
          &::after {
            -webkit-transform: scale(2);
            transform: scale(2);
            box-shadow: inset 0 0 0 1px var(--color-primary);
            background-color: transparent;
            background-color: transparent !important;
          }
        }
      }
    }
  }
  // Dot Light
  &.dot-light {
    .slick-dots {
      li {
        button {
          &::after {
            background: #ffffff;
            box-shadow: inset 0 0 0 5px #ffffff;
          }
        }
        &.slick-active {
          opacity: 1;
          button {
            &::after {
              -webkit-transform: scale(2);
              transform: scale(2);
              box-shadow: inset 0 0 0 1px var(--color-primary);
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}

/* Slick Arrow Button  */

.slider-activation {
  button {
    &.slick-arrow {
      position: absolute;
      top: 45%;
      z-index: 3;
      background: transparent;
      font-size: 15px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      @media #{$lg-layout} {
        width: 70px;
      }

      @media #{$md-layout} {
        width: 70px;
      }

      @media #{$sm-layout} {
        width: 100px;
        display: none !important;
      }

      &::before {
        line-height: 1;
        opacity: 0.75;
        font-size: 30px;
        position: relative;
        // top: -14px;
        transition: 0.5s;
      }
      &.slick-prev {
        position: absolute;
        left: -30px;
        @media (max-width: 1338px) and (min-width: 1200px) {
          left: -10px;
        }
        // background: -moz-linear-gradient(left,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        // background: -webkit-linear-gradient(left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        // background: linear-gradient(to right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$black-color', endColorstr='$black-color00',GradientType=1 );
        &::before {
          content: "";
          display: inline-block;
          top: 15%;
          width: 40px; /* 이미지의 너비 */
          height: 40px; /* 이미지의 높이 */
          background-image: url(/assets/images/icons/slick-arrow-left.png);
          background-size: contain;
          background-repeat: no-repeat;
          @media (max-width: 1200px) and (min-width: 1338px) {
            width: 30px; /* 이미지의 너비 */
            height: 30px; /* 이미지의 높이 */
          }
        }
        // @media #{$md-layout} {
        //     display: none !important;
        // }
        // @media #{$lg-layout} {
        //     display: none !important;
        // }
        // @media #{$laptop-device} {
        //     display: none !important;
        // }
      }
      &.slick-next {
        position: absolute;
        right: -30px;
        // background: -moz-linear-gradient(right,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        // background: -webkit-linear-gradient(right,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        // background: linear-gradient(to left,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$black-color', endColorstr='$black-color00',GradientType=1 );
        @media (max-width: 1338px) and (min-width: 1200px) {
          right: -10px;
        }
        &::before {
          content: "";
          display: inline-block;
          top: 15%;
          width: 40px; /* 이미지의 너비 */
          height: 40px; /* 이미지의 높이 */
          background-image: url(/assets/images/icons/slick-arrow-right.png);
          background-size: contain;
          background-repeat: no-repeat;
          @media (max-width: 1200px) and (min-width: 1338px) {
            width: 30px; /* 이미지의 너비 */
            height: 30px; /* 이미지의 높이 */
          }
        }
        // @media #{$md-layout} {
        //     display: none !important;
        // }
        // @media #{$lg-layout} {
        //     display: none !important;
        // }
        // @media #{$laptop-device} {
        //     display: none !important;
        // }
      }

      &:hover {
        &.slick-prev {
          position: absolute;
          left: -40px;
          @media (max-width: 1338px) and (min-width: 1200px) {
            left: -10px;
          }
          // @media #{$lg-layout} {
          //     left:-40px;

          // }
          // @media #{$md-layout} {
          //     padding-right: 10px;
          // }
          // @media #{$sm-layout} {
          //     padding-right: 10px;
          // }
        }
        &.slick-next {
          position: absolute;
          right: -40px;
          @media (max-width: 1338px) and (min-width: 1200px) {
            right: -10px;
          }
          // padding-left: 50px;
          // @media #{$md-layout} {
          //     padding-right: 10px;
          // }
          // @media #{$sm-layout} {
          //     padding-right: 10px;
          // }
        }
        &::before {
          opacity: 1;
        }
      }
    }
  }
}
