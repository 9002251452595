.ql-size {
    margin-bottom: 12px;
  }
  .reactquill {
    height: 400px;
  }
  .ql-container.ql-snow {
    height: 100% !important;
  }
  .ql-editor.ql-blank {
    height: 100% !important;
  }
  
  span.ql-picker-label svg {
    margin-bottom: 20px;
  }
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
      position: absolute;
       margin-top: 0px !important;
      right: 0;
      top: 40% !important;
      width: 18px;
     
  }
  p{
    color: var(--color-dark)
  }
   @media (max-width: 1200px){
      .ql-size {
          margin-bottom: 5px;
        }

      .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
          position: absolute;
          right: 0;
          top: 30% !important;
          width: 18px;
      }
    }
