/*===================
Equipments Styles 
=====================*/

//  Box Styles
.eq_box {
  position: relative;
  z-index: 2;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), box-shadow 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
  height: 350px;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 85%;
    display: block;
    z-index: 1;
    content: "";
    background: linear-gradient(to bottom, rgba(15, 15, 15, 0), rgba(15, 15, 15, 0.6) 100%);
    transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    cursor: pointer;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    display: block;
    z-index: 1;
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--color-primary) 100%);
    transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
    cursor: pointer;
    opacity: 0;
  }

  .thumbnail {
    text-align: center;
    a {
      display: block;
      img {
        transition: transform 1s ease, opacity 0.5s ease 0.25s;
        border-radius: 10px;
        width: auto;
        height: auto;
        max-width: 300px;
        max-height: 170px;
        margin: 30px auto;
      }
    }
  }

  .content {
    .inner {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 30px 30px;
      z-index: 2;
      width: 100%;
      z-index: 3;

      .content_heading {
        transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
      }
      .content_footer {
        opacity: 0;
        position: absolute;
        bottom: 35px;
        margin-top: 10px;
        transform: translateY(20px);
        line-height: 1.5em;
        max-width: 80%;
        transition: opacity 0.18s cubic-bezier(0.05, 0.2, 0.1, 1), transform 0.18s cubic-bezier(0.05, 0.2, 0.1, 1);
      }
    }
    .category_list {
      margin: -5px;
      margin-bottom: 5px;
      a {
        color: #ffffff;
        opacity: 0.9;
        display: inline-block;
        margin: 5px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }

    .title {
      margin-bottom: 0;
      a {
        color: #ffffff;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        @extend %transition;
        @media #{$lg-layout} {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 25px 55px rgba(30, 30, 29, 0.22);
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }

    .content {
      .inner {
        .content_heading {
          transform: translateY(-62px);
          transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1), transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
        }
        .content_footer {
          transform: translateY(0);
          opacity: 1;
          transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s, transform 0.65s cubic-bezier(0.05, 0.2, 0.1, 1) 0.15s;
        }
      }
    }
    .thumbnail {
      a {
        img {
          transform: scale(1.1);
          transition: all 9s cubic-bezier(0.1, 0.2, 7, 1);
        }
      }
    }
  }
}

.slick-space-gutter--15 {
  .im_box {
    margin: 50px 0;
  }
}
